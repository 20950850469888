import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import {
  TideItemForCountry,
  TideItemForLocation,
  TideItemForSeizoenWedstrijd,
  TideItemForTeam,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWildcardTeamPage,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import Seo from "../../components/seo";
import MapTeamPageComponents from "../../utils/mappers/map-team-page-components";
import GlobalContext from "../../contexts/global-provider";
import { PackageProvider } from "../../contexts/package-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

export interface TeamPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForTeam;
  location: TideItemForLocation;
  usps: TideItemForUspFolder;
  wildcardTeamPage: TideItemForWildcardTeamPage;
}

interface TeamPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
  teamId: string;
  locationId: string;
}

const TeamPageTemplate: React.FC<PageProps<TeamPageTemplateData, TeamPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs, teamId, locationId },
}) => {
  const globalContext = useContext(GlobalContext);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [country, setCountry] = useState<TideItemForCountry>();
  const [cancelPackagesSearch, setCancelPackagesSearch] = useState<boolean>(false);
  const { games, countries, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    (async () => {
      if (globalContext && globalContext.language !== language) {
        globalContext.setLanguage(language);
      }
      if (!games || games.length == 0) {
        await setGlobalData();
      }
    })();
  }, []);

  useEffect(() => {
    if (teamId && !isEmpty(games)) {
      const filteredGames = games.filter((g) => g.content?.general?.homeTeamId === teamId || g.content?.general?.awayTeamId === teamId);
      const eventIds = filteredGames.flatMap(
        (game) =>
          (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[])
            .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
            .map((es) => es.content?.general?.id) as string[]
      );
      if (!isEmpty(eventIds)) {
        setEventIds(eventIds);
      } else {
        setCancelPackagesSearch(true);
      }
    }
  }, [games]);

  useEffect(() => {
    if (countries) {
      setCountry(countries.find((x) => x.content?.general?.countryId == data.page.content?.general?.countryId));
    }
  }, [countries]);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            title={parse(data.page.content?.seo?.seoTitle)}
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />
          {globalContext.language === language && games.length > 0 && (
            <PackageProvider eventIds={eventIds} eventPage={false} cancelPackagesSearch={cancelPackagesSearch}>
              <MapTeamPageComponents
                website={data.website}
                page={data.page}
                usps={data.usps}
                location={data.location}
                breadcrumbs={breadcrumbs}
                wildcardTeamPage={data.wildcardTeamPage}
                country={country}
              />
            </PackageProvider>
          )}
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default TeamPageTemplate;

export const query = graphql`
  query TeamPageQuery($pageId: String, $websiteId: String, $language: String, $locationId: String) {
    wildcardTeamPage: tideItemForWildcardTeamPage(parentItem: { id: { eq: $websiteId } }) {
      __typename
      id
      name
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForPageTitleComponentFragment
        ...TideItemForIntroComponentFragment
        ...TideItemForOutroComponentFragment
        ...TideItemForTestimonialsFragment
        ...TideItemForOverviewComponentFragment
      }
    }
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForTeam(id: { eq: $pageId }) {
      name
      id
      content {
        general {
          hero {
            altText
            title
            url
          }
          title
          intro
          path
          logo {
            title
            altText
            url
          }
          venueId
          countryId
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
        outro {
          title
          text
          gallery {
            title
            altText
            url
          }
          founded
          nickname
          players
          rival
        }
        kiyoh {
          name
        }
      }
      parentItem {
        ... on TideItemForSporten {
          __typename
          content {
            general {
              path
            }
          }
          childItems {
            ... on TideItemForEvenementLocatie {
              __typename
              id
              name
              content {
                general {
                  id
                  capacity
                  title
                  text
                  image {
                    title
                    altText
                    url
                  }
                }
                address {
                  cityId
                }
              }
            }
          }
        }
      }
      childItems {
        ...BlocksFragment
        ...TideItemForQuickLinksSectionFragment
      }
    }
    location: tideItemForLocation(content: { general: { adminLocationId: { eq: $locationId } } }, language: { eq: $language }) {
      content {
        general {
          images {
            altText
            title
            url
          }
          text
          title
        }
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
